import React, { FC, useEffect } from 'react'

import FormControl from '@mui/material/FormControl'
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { useStyles } from './style'
import { FieldAttributes, FormikProps, FormikValues } from 'formik'

interface IProps {
  form: FormikProps<FormikValues>
  options: Array<{
    value: string
    label: string
    disallowCropTypes?: string[]
  }>
  field: FieldAttributes<{
    name: string
    value: string
  }>
}

export const CustomRadio: FC<IProps> = ({ form, field, options }) => {
  const classes = useStyles()

  const { name, value } = field
  const {
    setFieldValue,
    values: { cropType }
  } = form

  useEffect(() => {
    if (name !== 'risk') {
      return
    }

    const { value = null } =
      options.find(({ disallowCropTypes }) => {
        return !disallowCropTypes?.includes(cropType)
      }) || {}

    setFieldValue(name, value)
  }, [cropType])

  return (
    <FormControl className={classes.formControl} component='fieldset'>
      <RadioGroup
        name={name}
        value={value || ''}
        onChange={(event) => {
          setFieldValue(name, event.currentTarget.value)
        }}
      >
        {options.map(({ value, label, disallowCropTypes }, i) => (
          <FormControlLabel
            key={i}
            value={value}
            label={label}
            disabled={disallowCropTypes?.includes(cropType)}
            control={<Radio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircleIcon />} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
