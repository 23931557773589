import startOfDay from 'date-fns/startOfDay'

// Used to get the date for a calendar limited to the seasonal range of the site
// May 15th through August 31st.
export const getInitialDate = (): Date => {
  // default to todays date
  let initialDate = startOfDay(new Date())

  // Is before may 15th
  if (initialDate.getMonth() < 4 || (initialDate.getMonth() === 4 && initialDate.getDate() < 15)) {
    // set to last year aug 31st
    initialDate = new Date(initialDate.getFullYear() - 1, 7, 31)
  } else if (initialDate.getMonth() > 7) {
    // set to this year august 31st
    initialDate = new Date(initialDate.getFullYear(), 7, 31)
  }

  return initialDate
}
