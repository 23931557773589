import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface ApplicationState {
  step: number
  isShowSidebar: boolean
  isShowTerms: boolean
}

const initialState: ApplicationState = {
  step: 0,
  isShowSidebar: false,
  isShowTerms: false
}

export const setTermsAction = createAction<boolean>('application/setTerms')

export const setSidebarShowAction = createAsyncThunk<boolean, boolean>(
  'application/sidebarShow',
  (payload, { getState, dispatch }) => {
    const {
      application: { step }
    } = getState() as RootState
    if (step === 6 && !payload) {
      setTimeout(() => {
        dispatch(setPopoverStepAction(7))
      }, 0)
    }
    return payload
  }
)

export const setPopoverStepAction = createAsyncThunk<number, number>('application/setStep', (payload, { getState }) => {
  const {
    application: { step }
  } = getState() as RootState

  if (step === 7) {
    localStorage.setItem('isShownPopover', 'accept')
  }
  return payload
})

export const applicationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPopoverStepAction.fulfilled, (state, action) => {
      state.step = action.payload
    })
    .addCase(setSidebarShowAction.fulfilled, (state, action) => {
      state.isShowSidebar = action.payload
    })
    .addCase(setTermsAction, (state, action) => {
      state.isShowTerms = action.payload
    })
})
