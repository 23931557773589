import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useStyles } from './styles'

export const WeatherSection = () => {
  const classes = useStyles()
  return (
    <Box component={'section'} className={classes.container}>
      <Box className={classes.iconWrapper}>
        <img alt={'Weather icon'} width={45} height={50} src={'/images/icons/weatherIcon.svg'} />
      </Box>
      <Box component={'section'} className={classes.textWrapper}>
        <Typography variant={'h3'} component={'h3'}>
          Weather data providers
        </Typography>
        <Typography variant={'body1'} component={'p'}>
          Weather data used for modeling is provided by Environment and Climate Change Canada; Alberta Agriculture and
          Forestry; Manitoba Agriculture Weather Program; and Saskatchewan Public Safety Agency.
        </Typography>
      </Box>
    </Box>
  )
}
