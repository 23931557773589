import React, { Dispatch, SetStateAction, useMemo, useEffect, useState } from 'react'

import { MapContainer, TileLayer, GeoJSON, ScaleControl, Marker, LayersControl, LayerGroup } from 'react-leaflet'
import Leaflet from 'leaflet'
import Control from 'react-leaflet-custom-control'
import Alert from '@mui/material/Alert'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { Legend } from '../legend-component'
import { useStyles } from './style'
import { PopoverWrapper } from '../../../../../shared/components/popover-wrapper/popover-wrapper'
import { useDispatch, useSelector } from 'react-redux'
import { setPopoverStepAction } from '../../../../../store/application/reducer'
import { useMediaHook } from '../../../../../shared/hooks/useMediaHook'
import {
  heatmapDataSelector,
  hasHeatmapDataSelector,
  heatmapDateSelect,
  heatmapMapControlsSelector
} from '../../../../../store/heatmap/selectors'
import { ScreenComponent } from '../screen-component'
import { CenteringControls } from '../centering-controls-component'
import { StationsResponse, useFetchStationsMutation } from '../../../../../store/api/api'
import { ExportButton } from './ExportButton'

interface IProps {
  legend?: boolean
  controls?: boolean
  zoom?: boolean
  dragging?: boolean
  keyboard?: boolean
  setScreenUrl?: Dispatch<SetStateAction<string>>
}

const stationIcon = Leaflet.divIcon({
  html: `
<svg viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
  <circle cx="50" cy="50" r="3" />
</svg>`,
  iconSize: [6, 6],
  iconAnchor: [3, 3],
  className: 'station-marker'
})

export const ReactMap: React.FC<IProps> = ({
  legend,
  setScreenUrl,
  controls = true,
  zoom = true,
  dragging = true,
  keyboard = true
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const data = useSelector(heatmapDataSelector)
  const hasMapData = useSelector(hasHeatmapDataSelector)
  const heatmapDate = useSelector(heatmapDateSelect)
  const geoJsonKey = useMemo(() => new Date().getTime(), [data])
  const { isMobile } = useMediaHook()
  const openPopover = () => {
    dispatch(setPopoverStepAction(1))
  }
  const [fetchStations] = useFetchStationsMutation()
  const [stations, setStations] = useState<StationsResponse[]>([])
  const { center, zoomLevel } = useSelector(heatmapMapControlsSelector)

  useEffect(() => {
    fetchStations({})
      .unwrap()
      .then((data) => {
        setStations(data)
      })
  }, [])

  const show2022WarningBanner = useMemo(() => new Date(heatmapDate).getFullYear() === 2022, [heatmapDate])

  return (
    <Container component={'section'} className={classes.wrapper} disableGutters={true}>
      {show2022WarningBanner ? (
        <Alert severity='warning'>
          2022 maps are for illustration only and do not accurately reflect FHB risk at all locations
        </Alert>
      ) : null}
      <div className={`mapWrapper ${show2022WarningBanner ? 'mapWithBanner' : ''}`}>
        <MapContainer
          className='mapContainer'
          dragging={dragging}
          doubleClickZoom={zoom}
          trackResize={zoom}
          touchZoom={zoom}
          scrollWheelZoom={zoom}
          keyboard={keyboard}
          center={center}
          zoom={zoomLevel}
          maxZoom={11}
          minZoom={3}
          attributionControl={false}
          preferCanvas
          style={{ height: '100%', minHeight: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png'
          />
          <LayersControl position='bottomright'>
            <LayersControl.Overlay name='Stations'>
              <LayerGroup>
                {stations.map(({ id, location }) => (
                  <Marker
                    icon={stationIcon}
                    key={id}
                    position={{ lng: location.coordinates[0], lat: location.coordinates[1] }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
          <CenteringControls />
          {data && hasMapData ? (
            <GeoJSON
              data={data}
              key={geoJsonKey}
              style={(feature) => {
                let color = '#777'

                if (feature) {
                  if (feature.properties.risk === 1) {
                    color = '#F2A900'
                  } else if (feature.properties.risk === 2) {
                    color = '#D93637'
                  } else if (feature.properties.risk === 3) {
                    color = '#000000'
                  } else if (feature.properties.risk !== undefined) {
                    color = '#006F62'
                  }
                }

                return {
                  color,
                  weight: 0,
                  fillOpacity: 0.4
                }
              }}
            />
          ) : null}
          {!isMobile && controls && (
            <Control
              container={{ style: { border: 'none', display: 'flex', flexDirection: 'row' } }}
              position='topright'
            >
              <PopoverWrapper step={8} media={['desktop']}>
                <ExportButton disabled={!data} ref={React.createRef()} />
              </PopoverWrapper>
            </Control>
          )}
          {isMobile && controls && (
            <Control container={{ style: { border: 'none' } }} position={'topright'}>
              <Button onClick={openPopover} aria-label='Learn how to use the map'>
                <HelpOutlineIcon />
              </Button>
            </Control>
          )}
          <ScaleControl />
          {setScreenUrl && <ScreenComponent setScreenUrl={setScreenUrl} />}
          {legend && <Legend />}
        </MapContainer>
      </div>
    </Container>
  )
}
