import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCurrentUserQuery } from '../../store/api/api'

interface ProtectedRouteProps {
  children: React.ReactElement
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoading, isFetching, isSuccess } = useCurrentUserQuery()
  const navigate = useNavigate()

  if (!isLoading && !isFetching && !isSuccess) {
    navigate('/admin')
  }

  if (isLoading || isFetching) {
    return null
  }
  return children
}
