import React, { forwardRef } from 'react'
import { useMap } from 'react-leaflet'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setMapControlsAction } from '../../../../../store/heatmap/reducer'

interface PropTypes {
  disabled: boolean
}

export const ExportButton = forwardRef<HTMLButtonElement, PropTypes>(({ disabled }, ref) => {
  const navigate = useNavigate()
  const map = useMap()
  const dispatch = useDispatch()

  const onClickExport = () => {
    dispatch(setMapControlsAction({ zoomLevel: map.getZoom() - 1, center: map.getCenter() }))
    navigate('/map/export-report')
  }

  return (
    <Button className='exportBtn' disabled={disabled} onClick={onClickExport} ref={ref}>
      <img className={'exportImg'} alt={'Export icon'} src={'/images/icons/export.svg'} />
      <Typography variant={'subtitle1'} component={'p'}>
        Export
      </Typography>
    </Button>
  )
})
