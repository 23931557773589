import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import { useStyles } from './styles'
import { CommonAccordion, IAccordionData } from '../../shared/components/common-accordion'
import { CommonButton } from '../../shared/components/common-button'

export const FAQ: React.FC = () => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)

  return (
    <Container component={'section'} className={classes.wrapper}>
      <div className={classes.headerRow}>
        <Typography aria-level={1} variant='h3' component='h1'>
          Frequently Asked Questions
        </Typography>
        <div>
          <CommonButton onClick={() => setExpanded((value) => !value)}>
            {expanded ? 'Collapse' : 'Expand'} All
          </CommonButton>
        </div>
      </div>
      {data.map((item, index) => (
        <CommonAccordion capitalize={false} key={index} title={item.title} details={item.details} expanded={expanded} />
      ))}
    </Container>
  )
}

const data: IAccordionData[] = [
  {
    title: 'Who can use the FHB risk map?',
    details:
      'Anyone interested in knowing the FHB, FDK and DON risk levels in their area can use this map.  Using weather data across the Prairies, it provides a qualitative estimate of expected risk levels.  The risk level estimated for an area does not mean that every field in that area will experience such risk.  However, there is a good chance that the risk level indicated on the map for any crop on any specific day will match field observations.  Producers should conduct a field survey validation before making fungicide application decisions.'
  },
  {
    title: 'How do I use the FHB risk map?',
    details:
      'Steps on how the map works are provided on the homepage under “How it works” or by visiting the Map tab on the top right corner of the screen. '
  },
  {
    title: 'For what time period can I use the FHB risk map?',
    details:
      'The risk map is calculated for each day starting May 15 through to August 31.  Risk cannot be forecast beyond the current date.  The risk map uses weather data from the previous 2 weeks from more than 500 weather stations to calculate the risk level by location for a selected date.'
  },
  {
    title: 'Which provinces are supported?',
    details: 'Alberta, Saskatchewan and Manitoba'
  },
  {
    title: 'How accurate is the FHB risk map data?',
    details:
      'The models were developed from plot studies established at 15 locations across the three Canadian Prairie provinces – 5 locations at each Province and each location had 40 plots over a 3-year period. The models were validated at about 300 producer fields across the Prairies with accuracies ranging from 70 to 100%. '
  },
  {
    title: 'Do I have to pay to use the FHB risk map?',
    details:
      'The viewer is free to use. Funding for the research was provided through the Integrated Crop Agronomy Cluster Program, part of the Canadian Agricultural Partnership. The list of funding partners and weather data providers is on the home page.'
  },
  {
    title: 'How is the FHB risk calculated?',
    details:
      'FHB risk is calculated and updated each day from May 15 through Aug 31. On a given day, hourly weather data from 1:00 a.m. through midnight at each weather station for the previous 14-day period are used in the FHB risk models. The models were developed for Western Canada to generate risk values at each weather station. The FHB risk is then mapped out using a 10 km x 10 km grid for the prairie agricultural region. In each grid cell, the FHB risk is determined using inverse distance weighting from the risk values for the nearest 12 weather stations that are within 50 km of the grid cell.'
  },
  {
    title: 'Do the FHB risk maps for a specific date change after they are calculated?',
    details:
      'There may be times when weather data are delayed in being transmitted and this can create gaps in the hourly weather data needed at each station.  If a weather station has any gap in its hourly data over the past 14 days, the station is not used in the FHB risk calculation.  On each day, the FHB risk mapping tool acquires weather data going back 5 days and recalculates the risk values and re-creates the maps for those days. By going back 5 days, some of the gaps in the hourly weather data can be filled in and more weather stations will be available for the inverse distance weighting at each grid cell on the maps.  Thus, an FHB risk map for a given date may change slightly over the following 5-day period during which hourly weather data are being acquired and some data gaps may be filled in. '
  }
]
