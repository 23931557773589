import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import { useStyles } from './style';

export const PurposeSection: React.FC = () => {
  const classes = useStyles();
  return (
    <Container component={'section'} className={classes.sectionWrap}>
      <Box component={'section'} className={classes.textWrap}>
        <Typography variant={'h2'} component={'h2'}>
          Purpose
        </Typography>
        <Typography variant={'body1'} component={'p'}>
          To provide advanced assessment of potential FHB damage to Western Canadian cereal crops based on weather
          conditions and inform fungicide control decisions.
        </Typography>
      </Box>
      <List>
        {purposeSectionData.map((data, i) => {
          return (
            <ListItem key={i}>
              <ListItemIcon>
                <img alt={data.alt} src={data.src} />
              </ListItemIcon>
              <Typography variant={'h3'} component={'h3'}>
                {data.heading}
              </Typography>
              <ListItemText>
                <Typography variant={'body2'} component={'p'}>
                  {data.text}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

const purposeSectionData = [
  {
    alt: 'Customize map icon',
    src: '/images/icons/mapIcon.svg',
    heading: 'Customize map',
    text: 'View specific FHB damage estimates within your area of interest in Western Canada.',
  },
  {
    alt: 'Analyze Risk icon',
    src: '/images/icons/riskIcon.svg',
    heading: 'Analyze Risk',
    text: 'Get modeled estimates of potential FHB damage based on weather conditions.',
  },
  {
    alt: 'Export insights icon',
    src: '/images/icons/exportIcon.svg',
    heading: 'Export insights',
    text: 'Use potential FHB damage estimates for research and farming.',
  },
];
