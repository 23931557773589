import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  description: {
    margin: '0 0 20px'
  },

  selectedSetControls: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },
  error: {
    color: 'red',
    margin: '0 0 5px 0',
    fontSize: '0.8em'
  },
  tableWrapper: {
    height: '600px',
    marginTop: '20px'
  }
}))
