import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  selectWrapper: {
    '& .MuiSelect-select': {
      padding: '10px 32px 10px 10px',
      fontSize: '0.8em'
    }
  }
}))
