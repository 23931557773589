import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useMap } from 'react-leaflet'
import leafletImage from 'leaflet-image'

interface IProps {
  setScreenUrl: Dispatch<SetStateAction<string>>
}

export const ScreenComponent: FC<IProps> = ({ setScreenUrl }) => {
  const map = useMap()

  useEffect(() => {
    map &&
      // eslint-disable-next-line
      leafletImage(map, function (err: Error, canvas: any) {
        const svg = document.querySelector('.mapContainer svg')
        if (svg) {
          const xml = new XMLSerializer().serializeToString(svg)

          // make it base64
          const svg64 = btoa(xml)
          const img = new Image()

          img.onload = () => {
            canvas.getContext('2d').drawImage(img, 0, 0)
            setScreenUrl(canvas.toDataURL())
          }

          img.src = `data:image/svg+xml;base64,${svg64}`
        } else {
          setScreenUrl(canvas.toDataURL())
        }
      })
  }, [map])

  return null
}
