import React, { forwardRef } from 'react'
import { Button, ButtonProps } from '@mui/base/Button'

import { CustomButtonComponent } from './style'

interface SharedButtonInterface {
  type?: string
}
type Props = SharedButtonInterface & ButtonProps

export const CommonButton = forwardRef<HTMLButtonElement, Props>(({ type, ...props }, ref) => (
  <Button role={'button'} {...props} type={type} slots={{ root: CustomButtonComponent }} ref={ref}>
    {props.children}
  </Button>
))
