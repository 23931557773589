import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { LinkButton } from '../link-button'
import { DrawerComponent } from '../drawer-component'
import { useMediaHook } from '../../hooks/useMediaHook'
import { routeConfig } from '../../../config/route-config'

import { useStyles } from './style'
import { RootState } from '../../../store/store'
import { iShowSidebarSelector } from '../../../store/application/selectors'
import { MobileButtonsBar } from '../../../routes/Map/HeatMap/mobile-buttons-bar'
import Container from '@mui/material/Container'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import ListItem from '@mui/material/ListItem'

export const ToolbarComponent: FC = () => {
  const classes = useStyles()
  const location = useLocation()

  const { isMobile } = useMediaHook()
  const iShowSidebar = useSelector(iShowSidebarSelector)

  const buttonTitle = isMobile ? 'Use Map' : 'Use Risk Mapping Tool'

  const mapData = useSelector((state: RootState) => state.heatmap.data?.data)

  const isMap = location.pathname === '/map'
  const isHeatLayerShown = isMap && isMobile && Boolean(mapData)
  const isMapButtonShow = location.pathname === '/' || (isMobile && !isHeatLayerShown)

  return (
    <Toolbar component={'section'} className={classes.container}>
      <Box component={'section'} className={classes.toolBar}>
        <Link to={'/'}>
          <img
            alt={'University of Manitoba logo'}
            src={'/images/icons/logoIcon.svg'}
            width={isMobile ? 99 : 122}
            height={isMobile ? 47 : 59}
          />
        </Link>
        <Box component={'nav'} className={classes.navWrapper}>
          {!isMobile && (
            <>
              {routeConfig.map(({ path, name, nestedRoutes }, index) => {
                const nestedPathname = nestedRoutes?.map((nestedRoute) => nestedRoute.path)
                let pathname = [path]
                if (nestedPathname?.length) {
                  pathname = pathname.concat(nestedPathname)
                }
                const isPageActive = pathname.includes(location.pathname)
                if (path === '/map/weekly-report') {
                  return (
                    <Container component={'section'} key={index} sx={{ width: 'auto' }}>
                      <Accordion disableGutters={true} sx={{ boxShadow: 'none' }}>
                        <AccordionSummary sx={{ padding: 0 }}>
                          <span className={classes.link}>{name}</span>
                        </AccordionSummary>
                        <AccordionDetails sx={{ position: 'absolute', background: 'white', zIndex: 1000 }}>
                          <ListItem
                            component='a'
                            href='https://open.alberta.ca/publications/2830245'
                            target='_blank'
                            aria-label='Alberta Weekly Report'
                            title='Alberta Weekly Report'
                            className={classes.cropReportListItem}
                          >
                            <Typography variant='h6' component='p'>
                              Alberta
                            </Typography>
                          </ListItem>
                          <ListItem
                            component='a'
                            href='https://www.gov.mb.ca/agriculture/crops/seasonal-reports/crop-report-archive/index.html'
                            target='_blank'
                            aria-label='Manitoba Weekly Report'
                            title='Manitoba Weekly Report'
                            className={classes.cropReportListItem}
                          >
                            <Typography variant='h6' component='p'>
                              Manitoba
                            </Typography>
                          </ListItem>
                          <ListItem
                            component='a'
                            href='https://www.saskatchewan.ca/business/agriculture-natural-resources-and-industry/agribusiness-farmers-and-ranchers/market-and-trade-statistics/crops-statistics/crop-report'
                            target='_blank'
                            aria-label='Saskatchewan Weekly Report'
                            title='Saskatchewan Weekly Report'
                            className={classes.cropReportListItem}
                          >
                            <Typography variant='h6' component='p'>
                              Saskatchewan
                            </Typography>
                          </ListItem>
                        </AccordionDetails>
                      </Accordion>
                    </Container>
                  )
                }

                return (
                  <Link key={index} to={path} className={isPageActive ? classes.underlinedLink : classes.link}>
                    {name}
                  </Link>
                )
              })}
            </>
          )}
          {isMapButtonShow && <LinkButton link={'/map'} title={buttonTitle} />}
          {isMobile && <DrawerComponent />}
        </Box>
      </Box>
      {isMobile && isMap && !iShowSidebar && <MobileButtonsBar />}
    </Toolbar>
  )
}
