import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  tableWrapper: {
    height: '70vh',
    width: '95%',
    margin: '20px'
  },

  description: {
    margin: '0 0 20px'
  },

  metricsList: {
    margin: 0,
    padding: '10px 0',
    listStyle: 'none',
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: '15px'
    }
  },

  idWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  seasonExportRow: {
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    '& .MuiInputLabel-root': {
      marginRight: '10px',
      fontSize: '20px'
    },
    '& .MuiInputBase-root': {
      marginRight: '5px'
    },
    '& .MuiSelect-select': {
      padding: '5.5px 14px'
    }
  }
}))
