import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { PopoverOrigin } from '@mui/material/Popover'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { CommonButton } from '../common-button'
import { useStyles } from './styles'
import { useMediaHook } from '../../hooks/useMediaHook'
import { stepSelector } from '../../../store/application/selectors'
import { setPopoverStepAction, setSidebarShowAction } from '../../../store/application/reducer'
import { ClassNameMap } from '@mui/material'

const getBoxArrowClasses = (step: number, classes: ClassNameMap): string => {
  if (step === 6) {
    return [classes.greyArrow, classes.greyArrowLeftMiddle].join(' ')
  } else if (step === 7) {
    return [classes.greyArrow, classes.greyArrowBottomLeft].join(' ')
  } else if (step === 8) {
    return [classes.greyArrow, classes.greyArrowTop].join(' ')
  }

  return classes.greyArrow
}

const stepDetails = {
  2: 'Only up to the current date is available',
  3: 'Select the crop you would like to analyze on your map view.',
  4: 'Select the variety you would like to analyze on your map view.',
  5: 'Select which risk type you would like to analyze on your map view.',
  6: 'Optionally limit the agricultural area to just one province'
}

type IProps = {
  children: any
  step?: number
  media?: string[]
}
export const PopoverWrapper: FC<IProps> = ({ children, step, media }) => {
  const anchorEl = children.ref.current
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const { isMobile } = useMediaHook()
  const currentStep = useSelector(stepSelector)
  const [anchorOrigin, setAnchorOrigin] = useState<PopoverOrigin>({
    vertical: 'top',
    horizontal: 'right'
  })

  const dispatch = useDispatch()

  // toggles popovers when step changes
  useEffect(() => {
    setOpen(false)
    if (
      step === currentStep &&
      ((isMobile && media && media.includes('mobile')) || (!isMobile && media && media.includes('desktop')))
    ) {
      setOpen(true)
    }
  }, [currentStep, media])

  // handles popover anchor position based on step numbers
  useEffect(() => {
    switch (true) {
      case isMobile && step && [1].includes(step):
        setAnchorOrigin({
          vertical: 'bottom',
          horizontal: 'left'
        })
        break
      case step === 8:
        setAnchorOrigin({
          vertical: 'bottom',
          horizontal: 'right'
        })
        break
    }
  }, [currentStep])

  const handleClose = () => {
    dispatch(setPopoverStepAction(0))
  }

  const handleCloseByIconClick = () => {
    localStorage.setItem('isShownPopover', 'accept')
    dispatch(setPopoverStepAction(0))
  }

  const getClasses = () => {
    const popoverClasses = ['steps', classes.wrapper]

    if (isMobile) {
      popoverClasses.push('mobile')
    } else {
      popoverClasses.push('desktop')
    }

    switch (step) {
      case 1:
        popoverClasses.push('step-one')
        break
      case 2:
        popoverClasses.push('step-two')
        break
      case 3:
        popoverClasses.push('step-three')
        break
      case 4:
        popoverClasses.push('step-four')
        break
      case 5:
        popoverClasses.push('step-five')
        break
      case 6:
        popoverClasses.push('step-six')
        break
      case 7:
        popoverClasses.push('step-seven')
        break
      case 8:
        popoverClasses.push('step-eight')
        break
    }

    return popoverClasses.join(' ')
  }

  const next = () => {
    if (isMobile) {
      if (step === 1) {
        dispatch(setSidebarShowAction(true))
      } else if (step === 7) {
        dispatch(setSidebarShowAction(false))
      }
    }

    dispatch(setPopoverStepAction(Number(step) + 1))
  }

  return (
    <>
      {[2, 3, 4, 5, 6, 7].includes(step!) && currentStep === step && isMobile && (
        <Box component={'section'} className={classes.stepInSidebarMobile}>
          <Typography component={'p'} variant={'body2'}>
            {(step === 2 && stepDetails[2]) ||
              (step === 3 && stepDetails[3]) ||
              (step === 4 && stepDetails[4]) ||
              (step === 5 && stepDetails[5]) ||
              (step === 6 && stepDetails[6]) ||
              (step === 7 && 'Tap "Show Results" to view the risk map')}
          </Typography>
          <Box component={'section'} className={classes.nextStepWrap}>
            <Typography component={'p'} variant={'body2'}>
              Step {step} of 8
            </Typography>
            <CommonButton onClick={next}>Next</CommonButton>
          </Box>
        </Box>
      )}

      {step && anchorEl && (
        <Popover
          className={getClasses()}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
        >
          <Box component={'div'} className={getBoxArrowClasses(step, classes)} />
          <Box component={'section'} className={classes.headerWrap}>
            <Typography component={'h4'} variant={'h4'}>
              {(step === 1 && 'Filter your view') ||
                (step === 2 && 'Select the date') ||
                (step === 3 && 'Select your crop of interest') ||
                (step === 4 && 'Select the variety of interest') ||
                (step === 5 && 'Select the type of FHB risk') ||
                (step === 6 && 'Select a province') ||
                (step === 7 && 'Create a report') ||
                (step === 8 && 'Export the report')}
            </Typography>
            <IconButton onClick={handleCloseByIconClick}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Typography component={'p'} variant={'body2'}>
            {(step === 1 && 'Select filters to narrow down your desired map view.') ||
              (step === 2 && stepDetails[2]) ||
              (step === 3 && stepDetails[3]) ||
              (step === 4 && stepDetails[4]) ||
              (step === 5 && stepDetails[5]) ||
              (step === 6 && stepDetails[6]) ||
              (step === 7 && 'Click "Show Results" to view the risk map') ||
              (step === 8 && 'Export your map view along with your selected date, crop and variety')}
          </Typography>
          <Box component={'section'} className={classes.nextStepWrap}>
            <Typography component={'p'} variant={'body2'}>
              Step {step} of 8
            </Typography>
            <CommonButton onClick={next}>{step === 8 ? 'Close' : 'Next'}</CommonButton>
          </Box>
        </Popover>
      )}
      {children}
    </>
  )
}
