import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

import { CommonButton } from '../common-button'

import { modalContentStyles, useStyles } from './style'

interface ModalProps {
  open: boolean
  onClose: () => void
  onAccept?: () => void
  title: string
  children: React.ReactElement
  showAcceptButtons?: boolean
}

export const AgreementModalComponent: React.FC<ModalProps> = ({
  open,
  onAccept,
  onClose,
  title,
  children,
  showAcceptButtons = true
}) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={classes.modal}
      disableScrollLock={true}
    >
      <Box component={'section'} className={classes.modalWrapper} sx={modalContentStyles}>
        <Typography variant='h2' component='h2'>
          {title}
        </Typography>
        {children}
        <Box component={'section'} display={'flex'} justifyContent={'flex-end'}>
          {showAcceptButtons ? (
            <>
              <CommonButton onClick={onClose} style={{ backgroundColor: 'transparent' }}>
                Cancel
              </CommonButton>
              <CommonButton onClick={onAccept} className={classes.acceptBtn}>
                Accept
              </CommonButton>
            </>
          ) : (
            <CommonButton onClick={onClose} style={{ backgroundColor: 'transparent' }}>
              Close
            </CommonButton>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
