import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto'
  }
}))
