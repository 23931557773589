import React from 'react'
import { Form, useFormik, useField, FormikProvider } from 'formik'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { getStationHealthExport } from '../../../store/api/api'
import { CommonButton } from '../../../shared/components/common-button'
import { useStyles } from './styles'

interface ExportParams {
  year: string
}

const YearSelect: React.FC = () => {
  const fieldValues = useField({ name: 'year' })
  const field = fieldValues[0]
  const helpers = fieldValues[2]

  // first year we have export data available
  const startYear = 2024
  const years = [startYear]
  const currentYear = new Date().getFullYear()

  for (let i = 0; i < currentYear - startYear; i++) {
    years.push(startYear + i + 1)
  }

  return (
    <>
      <InputLabel id='export-season-select-year-label'>Export whole season (csv)</InputLabel>
      <Select
        labelId='export-season-select-year-label'
        value={field.value}
        onChange={(ev) => {
          helpers.setValue(ev.target.value)
        }}
      >
        {years.map((year) => {
          return (
            <MenuItem value={year} key={year}>
              {year}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}

export const SeasonExport: React.FC = () => {
  const classes = useStyles()
  const formik = useFormik({
    initialValues: {
      year: new Date().getFullYear().toString()
    },
    onSubmit: (values: ExportParams) => {
      getStationHealthExport(values.year)
    }
  })

  return (
    <FormikProvider value={formik}>
      <Form className={'form'}>
        <div className={classes.seasonExportRow}>
          <YearSelect />
          <CommonButton onClick={() => formik.submitForm()} type={'button'}>
            Export
          </CommonButton>
        </div>
      </Form>
    </FormikProvider>
  )
}
