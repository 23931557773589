import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { useStyles } from './styles'
import { useMediaHook } from '../../hooks/useMediaHook'

export interface IAccordionData {
  expanded?: boolean
  title: string
  details?: string
  subtitle?: string
  capitalize?: boolean
}

export const CommonAccordion: React.FC<IAccordionData> = ({
  expanded,
  title,
  subtitle,
  details,
  children,
  capitalize
}) => {
  const classes = useStyles()
  const { isMobile } = useMediaHook()
  const [isExpanded, setExpanded] = useState(expanded || false)

  useEffect(() => {
    setExpanded(expanded || false)
  }, [expanded])

  const modifiedTitle = subtitle && !isMobile ? title + ' • ' : title

  return (
    <Accordion disableGutters elevation={0} className={classes.accordion} expanded={isExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ fontSize: 26, color: 'black' }} />}
        className={classes.accordionSummary}
        onClick={() => setExpanded((value) => !value)}
      >
        <Box component={'section'} className={classes.titleWrapper}>
          <Typography
            sx={{ textTransform: capitalize ? 'capitalize' : 'none' }}
            aria-level={2}
            variant={'h4'}
            component={'h4'}
          >
            {modifiedTitle}
          </Typography>
          {subtitle && (
            <Typography variant={'body2'} component={'p'} className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
        {details ? (
          <Typography
            variant={'body2'}
            component={'p'}
            className={classes.details}
            dangerouslySetInnerHTML={{ __html: details }}
          />
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}
