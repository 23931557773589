import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'

import { useLoginMutation, useCurrentUserQuery } from '../../../store/api/api'
import { CommonButton } from '../../../shared/components/common-button'
import { useStyles } from './styles'

export const Login: React.FC = () => {
  const classes = useStyles()
  const [login] = useLoginMutation()
  const { isLoading, isFetching, isSuccess } = useCurrentUserQuery()

  const [globalError, setGlobalError] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && !isFetching && isSuccess) {
      navigate('/admin/home')
    }
  }, [isLoading, isFetching, isSuccess])

  if (isLoading || isFetching) {
    return null
  }

  return (
    <Container component={'section'} disableGutters={true} className={classes.container}>
      <Typography variant='h2' component='h1'>
        Login
      </Typography>
      <Formik
        initialValues={{
          username: '',
          password: ''
        }}
        validate={(values) => {
          const errors: { [key: string]: string } = {}
          if (!values.username) {
            errors['username'] = 'Please enter a username'
          }
          if (!values.password) {
            errors['password'] = 'Please enter a password'
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setGlobalError('')
          login(values)
            .unwrap()
            .then(() => {
              navigate('/admin/home')
            })
            .catch((err) => {
              if (err.status === 401) {
                setGlobalError('Incorrect username or password')
              } else {
                setGlobalError('Something went wrong')
              }
              setSubmitting(false)
            })
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            {globalError ? <p className={classes.error}>{globalError}</p> : null}
            <div className={classes.fieldRow}>
              <FormLabel htmlFor='username'>Username</FormLabel>
              <TextField
                type='text'
                name='username'
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.username && touched.username ? <p className={classes.error}>{errors.username}</p> : null}
            </div>
            <div className={classes.fieldRow}>
              <FormLabel htmlFor='password'>Password</FormLabel>
              <TextField
                type='password'
                name='password'
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.password && touched.password ? <p className={classes.error}>{errors.password}</p> : null}
            </div>
            <CommonButton type='submit' className={classes.submitButton} disabled={isSubmitting}>
              Login
            </CommonButton>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
