import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMap } from 'react-leaflet'
import Leaflet from 'leaflet'

import { heatmapDataSelector, hasHeatmapDataSelector } from '../../../../../store/heatmap/selectors'

export const CenteringControls: React.FC = () => {
  const map = useMap()
  const data = useSelector(heatmapDataSelector)
  const hasData = useSelector(hasHeatmapDataSelector)
  const prevHeatmapValuesRef = useRef({ data, hasData })

  useEffect(() => {
    if (!data || !hasData) {
      return
    }

    // Only reset the map when the data changes. If data is unchanged nad map renders, dont change bounds
    if (data !== prevHeatmapValuesRef.current.data || hasData !== prevHeatmapValuesRef.current.hasData) {
      const geoJsonLayer = Leaflet.geoJSON(data)
      const geoJsonBounds = geoJsonLayer.getBounds()

      map.fitBounds(geoJsonBounds)
      prevHeatmapValuesRef.current = {
        data,
        hasData
      }
    }
  }, [data, hasData])

  return null
}
