import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import format from 'date-fns/format'

import { heatmapDataSelector, heatmapRequestParamsSelector } from '../../../store/heatmap/selectors'
import { DownloadComponent } from './download-component'
import { cropTypeOptions, riskOptions } from '../HeatMap/filter-bar'
import { useFetchCurrentCropTypesQuery } from '../../../store/api/api'
import { ReactMap } from '../HeatMap/map-section/map-component'

import { useStyles } from './styles'

export const ExportReport: React.FC = () => {
  const classes = useStyles()

  const componentRef = useRef<HTMLElement>(null)
  const [screenUrl, setScreenUrl] = useState<string>('')
  const navigate = useNavigate()

  const mapData = useSelector(heatmapDataSelector)
  const requestParams = useSelector(heatmapRequestParamsSelector)
  const { currentData: cropTypes } = useFetchCurrentCropTypesQuery()

  useEffect(() => {
    !mapData && navigate('/map')
  })

  if (!cropTypes) {
    return null
  }

  const detailsData = [
    {
      icon: '/images/icons/map.svg',
      alt: 'map icon',
      title: 'Date:',
      detail: () => format(new Date(requestParams?.date), 'MMMM dd, yyyy')
    },
    {
      icon: '/images/icons/crop.svg',
      alt: 'crop type icon',
      title: 'Crop Type:',
      detail: () => {
        return cropTypeOptions.find(({ value }) => value === requestParams?.cropType)?.label
      }
    },
    {
      icon: '/images/icons/variety.svg',
      alt: 'variety icon',
      title: 'Variety:',
      detail: () => {
        return requestParams?.varietyType
          ?.map((varietyType) => {
            return cropTypes.find(({ label }) => label === varietyType)?.label
          })
          ?.join(', ')
      }
    },
    {
      icon: '/images/icons/warning.svg',
      alt: 'risk icon',
      title: 'Risk:',
      detail: () => {
        return riskOptions.find(({ value }) => {
          return value === requestParams.risk
        })?.label
      }
    }
  ]

  return (
    <>
      <Container component={'section'} className={classes.container} ref={componentRef}>
        <Box component={'section'} className={classes.titleWrapper}>
          <Box component={'section'} sx={{ display: 'flex' }}>
            <Typography variant={'h3'} component={'h1'}>
              {requestParams?.risk?.toUpperCase()} Risk Map
            </Typography>
          </Box>
          <DownloadComponent screenUrl={screenUrl} pageElement={componentRef} detailsData={detailsData} />
        </Box>
        <Box component={'section'} className={classes.mapBox}>
          <Box component={'section'} className={classes.mapWrapper}>
            <ReactMap
              setScreenUrl={setScreenUrl}
              controls={false}
              zoom={false}
              dragging={false}
              keyboard={false}
              legend
            />
          </Box>
          <Box component={'section'} className={classes.mapDetail}>
            <Typography variant={'h4'} component={'h3'} className={classes.mapDetailsTitle}>
              Map Details
            </Typography>
            <Box component={'section'} className={classes.detailsBox}>
              {detailsData.map((item, index) => {
                const isDividerShow = index < detailsData.length - 1
                return (
                  <Box component={'section'} key={index}>
                    <Box className={classes.detailItemBox}>
                      <Box className={classes.iconAndTitle}>
                        <img width={25} height={32} alt={item.alt} src={item.icon} className={classes.detailIcon} />
                        <Typography variant={'h4'} component={'h4'} className={classes.detailTitle}>
                          {item.title}
                        </Typography>
                      </Box>
                      <Typography component={'p'} className={classes.detailDescription}>
                        {item.detail()}
                      </Typography>
                    </Box>
                    {isDividerShow && <Divider sx={{ margin: 0 }} />}
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
        <Box component={'section'} className={classes.disclaimerBox}>
          <Typography variant={'h4'} component={'h4'}>
            Disclaimer
          </Typography>
          <Typography variant={'body2'} component={'p'} className={classes.disclaimerText}>
            Efforts have been make to ensure accuracy of the information provided on this tool. However, models do not
            have 100% accuracy so you are encouraged to conduct field observations to verify the information provided on
            this tool.
          </Typography>
        </Box>
      </Container>
    </>
  )
}
