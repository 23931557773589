import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { Layout } from '../shared/components/layout'
import { FAQ } from './FAQ'
import { Home } from './Home'
import { Map } from './Map'
import { Login } from './Admin/Login'
import { StationHealth } from './Admin/StationHealth'
import { ProtectedRoute } from './Admin/ProtectedRoute'
import { ExportReport } from './Map/ExportReport'
import { CropTypes } from './Admin/CropTypes'
import { Admin } from './Admin'
import { NewCropTypes } from './Admin/NewCropTypes'
import { useStyles } from './styles'

const App = () => {
  useStyles()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/map' element={<Map />} />
          <Route path='/map/export-report' element={<ExportReport />} />
          <Route path='/faq' element={<FAQ />} />
          <Route
            path='/admin/station-health'
            element={
              <ProtectedRoute>
                <StationHealth />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/crop-types/new'
            element={
              <ProtectedRoute>
                <NewCropTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/crop-types'
            element={
              <ProtectedRoute>
                <CropTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/home'
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route path='/admin' element={<Login />} />
          <Route path='*' element={<Home />} />
        </Route>
      </Routes>
    </LocalizationProvider>
  )
}
export default App
