import React from 'react'

import { StationHealthTable } from './StationHealthTable'
import { SeasonExport } from './SeasonExport'
import { useStyles } from './styles'
import { Title } from '../common/Title'
import { Wrapper } from '../common/Wrapper'

export const StationHealth: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <Wrapper>
        <Title>Station Health</Title>
        <SeasonExport />
        <p className={classes.description}>
          This table shows the previous 14 days of data, and whether it has 24 hours of data for each day.
        </p>
      </Wrapper>
      <div className={classes.tableWrapper}>
        <StationHealthTable />
      </div>
    </>
  )
}
