import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

import { AgreementModalComponent } from '../agreement-modal-component'
import { RootState } from '../../../store/store'
import { setPopoverStepAction, setTermsAction } from '../../../store/application/reducer'

export const TermsOfUse: React.FC = () => {
  const navigate = useNavigate()
  const showTerms = useSelector((state: RootState) => state.application.isShowTerms)
  const dispatch = useDispatch()

  const closeTerms = () => {
    sessionStorage.removeItem('isShownTerms')
    dispatch(setTermsAction(false))
    dispatch(setPopoverStepAction(0))
    navigate('/')
  }

  const acceptTerms = () => {
    sessionStorage.setItem('isShownTerms', 'accept')
    dispatch(setTermsAction(false))
  }

  return (
    <AgreementModalComponent open={showTerms} onClose={closeTerms} onAccept={acceptTerms} title={'Terms of Use'}>
      <Typography variant='body2' component='p'>
        Welcome to the Fusarium Head Blight (FHB) interactive tool. This tool provides information on the risk of FHB,
        Fusarium Damaged Kernel (FDK), and Deoxynivalenol (DON) across the agricultural region of the Canadian Prairies.
        Efforts have been made to ensure accuracy of the information provided on this tool. However, models do not have
        100% accuracy so you are encouraged to conduct field observations to verify the information provided on this
        tool. The risk maps are based on air temperature, humidity, and precipitation levels reported at weather
        stations.
        <strong>
          The risk model algorithms were developed independently of each other and were selected based on their
          individual statistical accuracy. Each algorithm uses different weather parameters, so the level of risk
          determined at a given location and a given day can vary between the different models for each crop and risk
          type.
        </strong>{' '}
        It is important to note that if your field is irrigated, the risk level displayed on this map may not be
        representative for your field. The FHB risk level on irrigated fields is normally higher than that shown on this
        map.
      </Typography>
    </AgreementModalComponent>
  )
}
