import React from 'react'

import { Button } from '@mui/base/Button'

import { CustomButtonComponent } from './style'

interface SharedButtonInterface {
  title: string
  link: string
}

type Props = SharedButtonInterface

export const LinkButton: React.FC<Props> = ({ title, link }) => {
  return (
    <Button slots={{ root: CustomButtonComponent }} to={link}>
      {title}
    </Button>
  )
}
