import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { FeatureCollection } from 'geojson'
import { difference, intersect, featureCollection } from '@turf/turf'
import Leaflet from 'leaflet'

import { Province } from '../../types/enum'
import geoJsonMask from './mask.json'
import geoJsonAlberta from './alberta.json'
import geoJsonManitoba from './manitoba.json'
import geoJsonSaskatchewan from './saskatchewan.json'
import { rootStateSelector } from '../store'

export const heatmapSelector = createDraftSafeSelector(rootStateSelector, (state) => state.heatmap)
export const heatmapDateSelect = createDraftSafeSelector(heatmapSelector, (state) => state.requestParams?.date)

function applyMask(heatmapData: FeatureCollection | undefined, province: Province | '') {
  if (!heatmapData) {
    return heatmapData
  }

  const intersectionList = []
  const heatmapFeatures = heatmapData.features
  let provinceFeatures = null
  let agriculturalAreaMask = geoJsonMask.features
  switch (province) {
    case Province.Alberta:
      provinceFeatures = geoJsonAlberta.features
      break
    case Province.Manitoba:
      provinceFeatures = geoJsonManitoba.features
      break
    case Province.Saskatchewan:
      provinceFeatures = geoJsonSaskatchewan.features
      break
  }

  if (provinceFeatures !== null) {
    const newMask = []
    for (const agriFeature of agriculturalAreaMask) {
      for (const provinceFeature of provinceFeatures) {
        const intersectArea = intersect(provinceFeature as any, agriFeature as any)
        newMask.push(intersectArea)
      }
    }

    agriculturalAreaMask = newMask as any
  }

  for (const agriFeature of agriculturalAreaMask) {
    let differenceArea = agriFeature as any
    for (const heatmapFeature of heatmapFeatures) {
      const intersectArea = intersect(agriFeature as any, heatmapFeature as any)
      if (intersectArea !== null) {
        differenceArea = difference(differenceArea, intersectArea as any)
        if (intersectArea.properties) {
          intersectArea.properties.risk = heatmapFeature.properties?.risk
        }
        intersectionList.push(intersectArea)
      }
    }

    if (differenceArea !== null) {
      intersectionList.push(differenceArea)
    }
  }

  return featureCollection(intersectionList)
}

export const heatmapDataSelector = createDraftSafeSelector(heatmapSelector, (state) =>
  applyMask(state.data?.data as FeatureCollection, state.requestParams.province)
)
export const hasHeatmapDataSelector = createDraftSafeSelector(heatmapSelector, (state) => {
  if (state.data && state.data.data) {
    // we return false here, as a feature collection came back with an empty features array
    const data = state.data.data as FeatureCollection
    if (data.type === 'FeatureCollection' && data.features.length === 0) {
      return false
    } else {
      return true
    }
  }

  return false
})

export const heatmapRequestParamsSelector = createDraftSafeSelector(heatmapSelector, (state) => state.requestParams)
export const heatmapMapControlsSelector = createDraftSafeSelector(heatmapSelector, (state) => {
  const center = new Leaflet.LatLng(state.center.lat, state.center.lng)
  return {
    zoomLevel: state.zoomLevel,
    center
  }
})
