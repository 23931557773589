import { configureStore } from '@reduxjs/toolkit'
import { heatmapReducer } from './heatmap/reducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { api } from './api/api'
import { applicationReducer } from './application/reducer'
import { weeklyReportsReducer } from './weekly-reports/reducer'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    heatmap: heatmapReducer,
    application: applicationReducer,
    weeklyReports: weeklyReportsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const rootStateSelector = (state: RootState) => state
