import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ColorVar } from '../../../styles/variables'

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    'div&': {
      'z-index': 1000
    },

    '& .MuiPaper-root': {
      overflow: 'visible',
      background: ColorVar.greyBrown,
      color: ColorVar.white,
      padding: '15px',
      '& h4': {
        marginBottom: '10px',
        zIndex: '+1'
      },
      '& p': {
        marginBottom: '10px'
      },
      '& .MuiIconButton-root': {
        stroke: ColorVar.white,
        strokeWidth: 2,
        zIndex: '+1',
        marginTop: '-10px',
        '& .MuiSvgIcon-root': {
          width: '16px',
          height: '16px'
        }
      }
    },
    '&.desktop.step-eight': {
      '& .MuiPaper-root': {
        marginTop: '21.75px',
        width: ' 359px'
      }
    },
    '&.desktop': {
      '&.step-one, &.step-two, &.step-three, &.step-four, &.step-five, &.step-six, &.step-seven': {
        '& .MuiPaper-root': {
          width: ' 359px',
          marginLeft: '20px'
        }
      }
    },
    '&.mobile.step-one, &.mobile.step-eight': {
      '& div.MuiPaper-root': {
        width: '344px',
        height: '172px',
        marginTop: '12px',
        position: 'relative'
      }
    },
    '&.mobile': {
      '& div.MuiPaper-root': {
        width: '100%'
      }
    }
  },
  nextStepWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stepInSidebarMobile: {
    background: ColorVar.greyBrown,
    borderRadius: '5px',
    color: ColorVar.white,
    padding: '15px',
    margin: '15px 0px',
    position: 'relative',
    '&::after': {
      content: '" "',
      position: 'absolute',
      bottom: '-7px',
      left: '30px',
      border: `22px solid ${ColorVar.greyBrown}`,
      borderRadius: '5px',
      transform: 'rotate(45deg)',
      zIndex: '-1',
      [theme.breakpoints.up(660)]: {
        top: '17px'
      }
    },
    '& > section': {
      marginTop: '15px'
    }
  },
  headerWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  greyArrow: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    backgroundColor: ColorVar.greyBrown,
    transform: 'rotate(45deg)',
    position: 'absolute',
    zIndex: '-1',
    [theme.breakpoints.up('tablet')]: {
      top: '20px',
      left: '-10px'
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      top: '-8px',
      left: '30px'
    }
  },
  greyArrowTop: {
    [theme.breakpoints.up('tablet')]: {
      top: '-8px',
      left: '300px'
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      top: '-8px',
      left: '180px'
    }
  },
  greyArrowLeftMiddle: {
    [theme.breakpoints.up('tablet')]: {
      top: 'auto',
      bottom: '67px',
      left: '-10px'
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      bottom: '50px',
      left: '30px'
    }
  },
  greyArrowBottomLeft: {
    [theme.breakpoints.up('tablet')]: {
      top: 'auto',
      bottom: '4px',
      left: '-10px'
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      bottom: '-8px',
      left: '30px'
    }
  }
}))
