import React from 'react'

import Control from 'react-leaflet-custom-control'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import CircleIcon from '@mui/icons-material/Circle'

import { useMediaHook } from '../../../../../shared/hooks/useMediaHook'

import { useStyles } from './style'

export const Legend: React.FC = () => {
  const classes = useStyles()
  const { isMobile } = useMediaHook()

  return (
    <Control position={'bottomleft'}>
      <Accordion className={classes.legendWrap} defaultExpanded={!isMobile}>
        <AccordionSummary className={classes.legendHeading} expandIcon={<KeyboardArrowDownRoundedIcon />}>
          <Typography component={'h2'} variant='body2'>
            Risk Legend
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {LegendData.map((data) => (
              <ListItem key={data.id}>
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText>{data.text}</ListItemText>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Control>
  )
}

const LegendData = [
  {
    id: 0,
    icon: <CircleIcon sx={{ color: '#000000' }} />,
    text: 'Very High'
  },
  {
    id: 1,
    icon: <CircleIcon sx={{ color: '#D93637' }} />,
    text: 'High'
  },
  {
    id: 2,
    icon: <CircleIcon sx={{ color: '#F2A900' }} />,
    text: 'Moderate'
  },
  {
    id: 3,
    text: 'Low',
    icon: <CircleIcon sx={{ color: '#006F62' }} />
  }
]
