import React from 'react'
import { Link } from 'react-router-dom'

import { Title } from './common/Title'
import { Wrapper } from './common/Wrapper'

export const Admin: React.FC = () => {
  return (
    <Wrapper>
      <Title>Admin</Title>
      <nav>
        <ul>
          <li>
            <Link to='/admin/station-health'>Station Health</Link>
          </li>
          <li>
            <Link to='/admin/crop-types'>Crop Types</Link>
          </li>
        </ul>
      </nav>
    </Wrapper>
  )
}
