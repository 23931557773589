import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: '50px',
    '& .MuiTypography-h3': {
      marginBottom: '45px'
    },
    '&.MuiContainer-root': {
      padding: '79px 150px 0 136px',
      [theme.breakpoints.between(0, 'tablet')]: {
        padding: '25px 29px 0 20px'
      }
    }
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}))
