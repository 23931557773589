import { createAction, createReducer } from '@reduxjs/toolkit'
import Leaflet from 'leaflet'

import { Province } from '../../types/enum'
import { HeatmapResponse } from '../api/api'
import { getInitialDate } from '../../shared/initialDate'

export type RequestParams = {
  date: string
  cropType: string
  varietyType: string[]
  risk: string
  province: Province | ''
}

interface HeatmapState {
  data: HeatmapResponse | null
  requestParams: RequestParams
  center: {
    lat: number
    lng: number
  }
  zoomLevel: number
}

interface SetMapControlsAction {
  center: Leaflet.LatLng
  zoomLevel: number
}

const initialState: HeatmapState = {
  data: null,
  requestParams: {
    date: getInitialDate().toString(),
    cropType: 'winterWheat',
    varietyType: [],
    risk: 'fhb',
    province: ''
  },
  center: { lat: 56.089371390397, lng: -104.784531135578 },
  zoomLevel: 5
}

export const setHeatmapDataAction = createAction<HeatmapResponse>('heatmap/setData')
export const clearHeatmapDataAction = createAction('heatmap/clearData')
export const setHeatmapRequestParamsAction = createAction<RequestParams>('heatmap/setRequestParams')
export const clearHeatmapRequestParamsAction = createAction('heatmap/clearRequestParams')
export const setMapControlsAction = createAction<SetMapControlsAction>('heatmap/setMapControls')

export const heatmapReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setHeatmapDataAction, (state, action) => {
      state.data = action.payload
    })
    .addCase(setHeatmapRequestParamsAction, (state, action) => {
      state.requestParams = action.payload
    })
    .addCase(clearHeatmapRequestParamsAction, (state) => {
      state.requestParams = initialState.requestParams
    })
    .addCase(clearHeatmapDataAction, (state) => {
      state.data = null
    })
    .addCase(setMapControlsAction, (state, action) => {
      state.center = {
        lat: action.payload.center.lat,
        lng: action.payload.center.lng
      }
      state.zoomLevel = action.payload.zoomLevel
    })
})
