import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'

import { useMediaHook } from '../../../../../shared/hooks/useMediaHook'
import { clearHeatmapDataAction } from '../../../../../store/heatmap/reducer'

import { useStyles } from './style'

interface IProps {
  closeMenuHandler?: () => void
  touched: boolean
}

export const FilterBarHeader: FC<IProps> = ({ touched, closeMenuHandler }) => {
  const { isMobile } = useMediaHook()
  const classes = useStyles()
  const dispatch = useDispatch()

  const clearMap = () => {
    dispatch(clearHeatmapDataAction())
  }

  return (
    <Container
      aria-label='Select'
      sx={{ display: 'flex', padding: '0px 20px' }}
      component={'section'}
      className={classes.wrapper}
    >
      <Typography aria-level={1} component={'h1'} variant={'h3'}>
        Select
      </Typography>
      <Box component={'section'}>
        {touched && (
          <Button type={'reset'} color='inherit' variant={'outlined'} onClick={clearMap}>
            Clear
          </Button>
        )}

        {isMobile && (
          <IconButton aria-label='Close filter' onClick={closeMenuHandler}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Container>
  )
}
