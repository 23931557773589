import React, { FC, useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearIcon from '@mui/icons-material/Clear'
import { FieldAttributes, FormikProps } from 'formik'
import classNames from 'classnames'

import { clearIconStyle, useStyles } from './style'
import { PopperProps } from '@mui/material/Popper'
import { useFetchCurrentCropTypesQuery, CropTypeRecord } from '../../../../../store/api/api'

interface IProps {
  form: FormikProps<{ cropType: string }>
  field: FieldAttributes<{
    name: string
    value: string[]
  }>
}

export const CustomAutocomplete: FC<IProps> = ({ form, field }) => {
  const classes = useStyles()
  const { currentData: cropTypes } = useFetchCurrentCropTypesQuery()

  const name = field.name
  // formik seems to default cast this as any
  const selected = field.value as string[]
  const { setFieldValue } = form

  const [isOpened, setIsOpened] = useState(false)
  const [placement, setPlacement] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<CropTypeRecord[]>([])

  const openHandler = () => setIsOpened(!isOpened)

  useEffect(() => {
    !isOpened && setPlacement('')
  }, [isOpened])

  useEffect(() => {
    if (cropTypes) {
      const filteredOptions = cropTypes.filter(({ cropTypeName }) => cropTypeName === form.values.cropType)
      setFilteredOptions(filteredOptions)
    }
  }, [form.values, cropTypes])

  useEffect(() => {
    if (cropTypes) {
      const variety = cropTypes.find(({ label }) => label === selected[0])
      const { cropType } = form.values

      if (cropType && cropType !== variety?.cropTypeName) {
        setFieldValue(name, [])
      }
    }
  }, [form.values.cropType, cropTypes])

  if (!cropTypes) {
    return null
  }

  return (
    <Autocomplete<CropTypeRecord | null>
      PopperComponent={(props: PopperProps) => {
        return (
          <Popper
            {...props}
            keepMounted={true}
            modifiers={[
              {
                name: 'placementLogger',
                enabled: true,
                phase: 'main',
                fn({ state }) {
                  setPlacement(state.placement)
                }
              }
            ]}
            className={classNames(props.className, placement, classes.popperWrapper)}
          />
        )
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      className={classNames(placement, classes.wrapper, isOpened ? classes.opened : null)}
      disablePortal={true}
      options={filteredOptions}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Typography component={'span'} className={classes.listText} variant={'body2'}>
              {option?.label}
            </Typography>
          </li>
        )
      }}
      value={cropTypes.find((option) => option.label === selected[0]) || null}
      ChipProps={{ deleteIcon: <ClearIcon style={clearIconStyle} /> }}
      onOpen={openHandler}
      onClose={openHandler}
      renderInput={(params) => {
        const placeholder = selected.length ? '' : 'Select Variety Type'
        return <TextField aria-label={'Choose variety'} placeholder={placeholder} {...params} />
      }}
      onChange={(event, selected) => {
        setFieldValue(name, [selected?.label])
      }}
    />
  )
}
