import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GeoJsonObject } from 'geojson'
import { Point } from 'geojson'
import { Province } from '../../types/enum'

export interface ApiError {
  field: string
  message: string
}

export type HeatmapResponse = {
  id: number
  cropType: string
  riskType: string
  date: string
  data: GeoJsonObject
}
export interface HeatmapRequest {
  date: Date | string
  cropType: string
  varietyType: string
  risk: string
  province?: Province | ''
}

export interface WeeklyReportsResponse {
  id: number
  date: Date
  province: string
  report: string
}
export interface WeeklyReportsRequest {
  province?: string
  year: string
}

export interface StationsResponse {
  id: number
  externalId: string
  name: string
  elevation: number
  province: string
  location: Point
}

export interface StationsRequest {
  province?: string
}

export interface StationHealthRequest {
  date: string
}

export interface StationHealthResponseRecord {
  id: number
  date: string
  fhb_measurement_type: 'air_temperature' | 'precipitation' | 'relative_humidity'
  measurement_hourly_count: number
  externalId: string
  province: string
}

export interface LoginRequest {
  username: string
  password: string
}

export interface CropTypeSetResponseRecord {
  id: number
  name: string
  active: boolean
}

export interface CreateCropTypeSetRequest {
  name: string
  file: File
}

export interface CropTypeRecord {
  id: number
  cropTypeName: 'barley' | 'durum' | 'springWheat' | 'winterWheat'
  label: string
  value: string
  cropTypeSetId: number
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
    paramsSerializer: (params) => {
      return Object.keys(params)
        .map((key) => {
          const value = params[key]

          if (Array.isArray(value)) {
            return value.map((el, i) => `${key}[]=${params[key][i]}`).join('&')
          }

          return `${key}=${params[key]}`
        })
        .join('&')
    }
  }),
  endpoints: (builder) => ({
    fetchHeatmap: builder.mutation<HeatmapResponse, HeatmapRequest>({
      query: (params) => {
        const paramsToSend = { ...params }
        // province is handled locally, dont need to send to the server
        delete paramsToSend['province']
        return {
          url: 'heatmap',
          method: 'GET',
          params: paramsToSend
        }
      }
    }),
    fetchWeeklyReports: builder.mutation<WeeklyReportsResponse[], WeeklyReportsRequest>({
      query: (credentials) => ({
        url: 'weekly-reports',
        method: 'GET',
        params: credentials
      })
    }),
    fetchStations: builder.mutation<StationsResponse[], StationsRequest>({
      query: () => ({
        url: 'stations',
        method: 'GET'
      })
    }),
    fetchStationHealth: builder.query<StationHealthResponseRecord[], void>({
      query: () => ({
        url: 'station-health',
        method: 'GET'
      })
    }),
    currentUser: builder.query<any, void>({
      query: () => ({
        url: 'auth/current',
        method: 'GET'
      })
    }),
    login: builder.mutation<void, LoginRequest>({
      query: (values) => ({
        url: 'auth/login',
        method: 'POST',
        body: values
      })
    }),
    fetchCropTypeSets: builder.query<CropTypeSetResponseRecord[], void>({
      query: () => ({
        url: 'crop-type-set',
        method: 'GET'
      })
    }),
    createCropTypeSet: builder.mutation<void, CreateCropTypeSetRequest>({
      query: (values) => {
        const formData = new FormData()
        formData.set('name', values.name)
        formData.set('file', values.file)
        return {
          url: 'crop-type-set',
          method: 'POST',
          body: formData
        }
      }
    }),
    setCropTypeSetAsActive: builder.mutation<void, number>({
      query: (id) => ({
        url: `crop-type-set/${id}/activate`,
        method: 'PUT'
      })
    }),
    deleteCropTypeSet: builder.mutation<void, number>({
      query: (id) => ({
        url: `crop-type-set/${id}`,
        method: 'DELETE'
      })
    }),
    fetchCropTypes: builder.query<CropTypeRecord[], number>({
      query: (id) => ({
        url: `crop-type/${id}`,
        method: 'GET'
      })
    }),
    fetchCurrentCropTypes: builder.query<CropTypeRecord[], void>({
      query: () => ({
        url: `crop-type/current`,
        method: 'GET'
      })
    })
  })
})

export const getStationHealthExport = async (year: string) => {
  window.open(`${process.env.REACT_APP_API_URL}/station-health/export/${year}`)
}

export const {
  useFetchHeatmapMutation,
  useFetchWeeklyReportsMutation,
  useFetchStationsMutation,
  useFetchStationHealthQuery,
  useLoginMutation,
  useCurrentUserQuery,
  useFetchCropTypeSetsQuery,
  useCreateCropTypeSetMutation,
  useDeleteCropTypeSetMutation,
  useSetCropTypeSetAsActiveMutation,
  useLazyFetchCropTypesQuery,
  useFetchCurrentCropTypesQuery
} = api
