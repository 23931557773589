import React from 'react'
import { FieldAttributes, FormikValues, FormikProps } from 'formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { Province } from '../../../../../types/enum'
import { useStyles } from './style'

interface Props {
  form: FormikProps<FormikValues>
  field: FieldAttributes<{
    name: string
    value: string
  }>
}

export const ProvinceSelect: React.FC<Props> = ({ form, field }) => {
  const classes = useStyles()
  return (
    <Select
      className={classes.selectWrapper}
      value={field.value}
      displayEmpty
      onChange={(e) => {
        form.setFieldTouched(field.name)
        form.setFieldValue(field.name, e.target.value)
      }}
    >
      <MenuItem value=''>View All</MenuItem>
      <MenuItem value={Province.Alberta}>Alberta</MenuItem>
      <MenuItem value={Province.Manitoba}>Manitoba</MenuItem>
      <MenuItem value={Province.Saskatchewan}>Saskatchewan</MenuItem>
    </Select>
  )
}
