import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

import { routeConfig } from '../../../config/route-config';

import { useStyles } from './style';
import { useMediaHook } from '../../hooks/useMediaHook';

export const DrawerComponent: React.FC = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { isMobile } = useMediaHook();
  const classes = useStyles();

  const menuHandler = () => setDrawerOpened(!drawerOpened);

  return (
    <Box component={'section'} className={classes.menuButtonWrapper}>
      <Button
        sx={{ width: '21px', minWidth: '21px' }}
        type='button'
        aria-label='Open menu button'
        onClick={menuHandler}
      >
        <MenuIcon className={classes.navButton} />
      </Button>
      <Drawer PaperProps={{ classes: { root: classes.drawer } }} open={drawerOpened}>
        <Box className={classes.menuHeader}>
          <Link to={'/'}>
            <img
              alt={'logo'}
              src={'./images/icons/logoIcon.svg'}
              width={isMobile ? 99 : 122}
              height={isMobile ? 47 : 59}
            />
          </Link>
          <Button
            sx={{ width: '21px', minWidth: '21px' }}
            type='button'
            aria-label={'Close menu button'}
            name='Close menu button'
            onClick={menuHandler}
          >
            <CloseIcon className={classes.navButton} />
          </Button>
        </Box>
        <List component={'ul'}>
          {routeConfig.map(({ path, name }, index) => {
            if (path === '/map/weekly-report') {
              return (
                <Container component={'section'} key={index}>
                  <Accordion disableGutters={true} sx={{ boxShadow: 'none' }}>
                    <AccordionSummary sx={{ padding: 0 }}>
                      <Typography variant='h6' component='p'>
                        {name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ListItem
                        sx={{ padding: '8px 0' }}
                        component='a'
                        href='https://open.alberta.ca/publications/2830245'
                        target='_blank'
                        aria-label='Alberta Weekly Report'
                        title='Alberta Weekly Report'
                      >
                        <span className={classes.typography}>
                          <Typography variant='h6' component='p'>
                            Alberta
                          </Typography>
                        </span>
                      </ListItem>
                      <ListItem
                        sx={{ padding: '8px 0' }}
                        component='a'
                        href='https://www.gov.mb.ca/agriculture/crops/seasonal-reports/crop-report-archive/index.html'
                        target='_blank'
                        aria-label='Manitoba Weekly Report'
                        title='Manitoba Weekly Report'
                      >
                        <span className={classes.typography}>
                          <Typography variant='h6' component='p'>
                            Manitoba
                          </Typography>
                        </span>
                      </ListItem>
                      <ListItem
                        sx={{ padding: '8px 0' }}
                        component='a'
                        href='https://www.saskatchewan.ca/business/agriculture-natural-resources-and-industry/agribusiness-farmers-and-ranchers/market-and-trade-statistics/crops-statistics/crop-report'
                        target='_blank'
                        aria-label='Saskatchewan Weekly Report'
                        title='Saskatchewan Weekly Report'
                      >
                        <span className={classes.typography}>
                          <Typography variant='h6' component='p'>
                            Saskatchewan
                          </Typography>
                        </span>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                </Container>
              )
            }
            return (
              <ListItem key={index} onClick={menuHandler}>
                <Link to={path} className={classes.typography}>
                  <Typography variant='h6' component='p'>
                    {name}
                  </Typography>
                </Link>
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </Box>
  );
};
