import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import subDays from 'date-fns/subDays'
import startOfDay from 'date-fns/startOfDay'
import format from 'date-fns/format'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'

import { useFetchStationHealthQuery } from '../../../store/api/api'
import { useStyles } from './styles'

type TableRow = {
  id: string
  province: string
  values: {
    [date: string]: DateValues
  }
}

type DateValues = {
  airTemperature: number
  precipitation: number
  relativeHumidity: number
}

interface GridCellExpandProps {
  value: DateValues
}

const StationHealthCell: React.FC<GridCellExpandProps> = React.memo(
  ({ value }) => {
    const classes = useStyles()

    return (
      <ul className={classes.metricsList}>
        <li>
          <span>Temp:</span>
          <span>{value.airTemperature === 24 ? <CheckIcon color='success' /> : <CancelIcon color='error' />}</span>
        </li>
        <li>
          <span>Precip:</span>
          <span>{value.precipitation === 24 ? <CheckIcon color='success' /> : <CancelIcon color='error' />}</span>
        </li>
        <li>
          <span>RH:</span>
          <span>{value.relativeHumidity === 24 ? <CheckIcon color='success' /> : <CancelIcon color='error' />}</span>
        </li>
      </ul>
    )
  },
  (oldProps, newProps) =>
    oldProps.value.airTemperature === newProps.value.airTemperature &&
    oldProps.value.precipitation === newProps.value.precipitation &&
    oldProps.value.relativeHumidity === newProps.value.relativeHumidity
)

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    renderCell: ({ value }) => {
      const classes = useStyles()
      return <div className={classes.idWrapper}>{value}</div>
    }
  },
  {
    field: 'province',
    headerName: 'Province',
    width: 100,
    renderCell: ({ value }) => {
      const classes = useStyles()
      return <div className={classes.idWrapper}>{value}</div>
    }
  }
]

const today = startOfDay(new Date())
for (let i = 0; i < 14; i++) {
  const date = subDays(today, 15 - (i + 1))
  const getter = (date: Date) => {
    return (value: unknown, row: TableRow) => {
      const fields = row.values[date.toISOString()]
      return fields || { airTemperature: 0, precipitation: 0, relativeHumidity: 0 }
    }
  }
  columns.push({
    field: date.toISOString(),
    headerName: format(date, 'MMM do'),
    width: 130,
    valueGetter: getter(date),
    renderCell: ({ value }) => <StationHealthCell value={value} />
  })
}

const provinceDisplayNames = {
  Alberta: 'Alberta',
  Manitoba: 'Manitoba',
  Saskatchewan: 'SK'
}

export const StationHealthTable: React.FC = () => {
  const { currentData } = useFetchStationHealthQuery()

  const [stationHealthRecords, setStationHealthRecords] = useState<Array<TableRow>>([])

  useEffect(() => {
    if (currentData) {
      const records: {
        [externalId: string]: {
          province: string
          values: {
            [date: string]: {
              airTemperature: number
              precipitation: number
              relativeHumidity: number
            }
          }
        }
      } = {}
      for (const row of currentData) {
        if (!records[row.externalId]) {
          records[row.externalId] = {
            values: {},
            province: row.province
          }
        }

        // removes the timezone stamp, so it treats the date as local time
        const dateKey = startOfDay(new Date(row.date.replace('.000Z', ''))).toISOString()
        if (!records[row.externalId].values[dateKey]) {
          records[row.externalId].values[dateKey] = {
            airTemperature: 0,
            precipitation: 0,
            relativeHumidity: 0
          }
        }

        const values = records[row.externalId].values[dateKey]

        if (row.fhb_measurement_type === 'air_temperature') {
          values.airTemperature = row.measurement_hourly_count
        }
        if (row.fhb_measurement_type === 'precipitation') {
          values.precipitation = row.measurement_hourly_count
        }
        if (row.fhb_measurement_type === 'relative_humidity') {
          values.relativeHumidity = row.measurement_hourly_count
        }
      }

      setStationHealthRecords(
        Object.keys(records).map((externalId) => ({
          id: externalId,
          values: records[externalId].values,
          province: provinceDisplayNames[records[externalId].province as keyof typeof provinceDisplayNames]
        }))
      )
    }
  }, [currentData, setStationHealthRecords])

  return <DataGrid columns={columns} rows={stationHealthRecords} getRowHeight={() => 'auto'} />
}
