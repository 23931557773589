import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import { useMediaHook } from '../../../shared/hooks/useMediaHook'

import { useStyles } from './style'

export const HowItWorksSection: React.FC = () => {
  const classes = useStyles()
  const { isMobile } = useMediaHook()
  return (
    <Container component={'section'} sx={{ display: 'flex' }} disableGutters={true} className={classes.sectionWrap}>
      <Box component={'section'} className={classes.mapPicWrap}>
        <img
          alt={'preview of map with data'}
          src={isMobile ? '/images/components/howItWorksMobile.png' : '/images/components/howItWorks.png'}
          className={classes.mapImage}
        />
      </Box>
      <Box component={'section'} className={classes.listWrap}>
        <Typography variant={'h2'} component={'h2'}>
          How it works
        </Typography>
        <List>
          {howItWorksData.map((data) => {
            return (
              <ListItem key={data.number}>
                <ListItemIcon className={classes.iconWrap}>
                  <Box className={classes.listIcon}>{data.number}</Box>
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={'h3'} component={'h3'}>
                    {data.heading}
                  </Typography>
                  <Typography variant={'body2'} component={'p'}>
                    {data.text}
                  </Typography>
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Container>
  )
}

const howItWorksData = [
  {
    number: '1',
    heading: 'Select your map view',
    text: 'Select a FHB Risk parameter, date, crop and variety to specify estimates of interest to you.'
  },
  {
    number: '2',
    heading: 'View FHB Risk map',
    text: 'Get a map view of the risk estimates of interest to you.'
  },
  {
    number: '3',
    heading: 'Download your map',
    text: 'Once you have a map with the risk estimates of interest to you, download or print the map.'
  }
]
