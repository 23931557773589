import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import MaterialLink from '@mui/material/Link'
import { useDispatch } from 'react-redux'

import { AgreementModalComponent } from '../agreement-modal-component'
import { useMediaHook } from '../../hooks/useMediaHook'

import { useStyles } from './style'
import { setTermsAction } from '../../../store/application/reducer'

function FooterComponent() {
  const classes = useStyles()
  const [isPolicyModalVisible, showPolicyModal] = useState<boolean>(false)
  const { isMobile } = useMediaHook()
  const dispatch = useDispatch()

  const onTermsModalClick = () => {
    dispatch(setTermsAction(true))
  }
  const onPolicyModalClick = () => showPolicyModal(!isPolicyModalVisible)

  return (
    <>
      <Container component={'section'} sx={{ display: 'flex' }} className={classes.footerWrap}>
        <Link to={'/'}>
          <img
            alt={'University of Manitoba logo'}
            src={'./images/icons/logoIcon.svg'}
            width={isMobile ? 99 : 122}
            height={isMobile ? 47 : 59}
          />
        </Link>
        <Typography variant={'body1'} component={'p'}>
          © 2021 University of Manitoba
        </Typography>
        <Box component={'section'} className={classes.linkWrap}>
          <MaterialLink
            sx={{ marginRight: '40px' }}
            component='button'
            variant='body2'
            underline='always'
            onClick={onPolicyModalClick}
          >
            Privacy policy
          </MaterialLink>
          <MaterialLink component='button' variant='body2' underline='always' onClick={onTermsModalClick}>
            Terms of use
          </MaterialLink>
        </Box>
      </Container>
      <AgreementModalComponent
        open={isPolicyModalVisible}
        onClose={onPolicyModalClick}
        title={'Privacy policy'}
        showAcceptButtons={false}
      >
        <>
          <Typography variant='body2'>
            Thank you for visiting this site. Your privacy is important and this Privacy Policy statement will help you
            to understand the information we collect, why we collect it, and what we do with it.
          </Typography>
          <Typography variant='body2'>
            When you visit this site, your web browser sends the URL of the page you are visiting and your IP address.
            It may set digital markers or “cookies” on your browser or read cookies that are already on your browser.
            This way, if it is the first time you are visiting the FHB risk map page, the guide on how to use the risk
            map will appear but it will not appear on subsequent visits to the site.
          </Typography>
          <Typography variant='body2'>
            We do not rent, trade or sell your information to third parties and we do not use any information collected
            on this website for advertising. Data collected are aggregated without personal identifiers and used mainly
            for the purpose of performing web analytics such as the number of unique visitors, pages visited, and the
            length of visit. We use Google Analytics for this analysis. Google Analytics is a third-party service
            provider based in the United States of America, which means that the information collected is transmitted
            outside of Canada and may be subject to USA laws, including the USA PATRIOT Act. In addition, Google
            operates servers in other countries on which the web analytics data may be processed. Consequently, the data
            may be subject to the governing legislation of the country where it is processed.
          </Typography>
          <Typography variant='body2'>
            For more information about Google Analytics privacy policies, please visit their website:{' '}
            <a href='https://policies.google.com/technologies/partner-sites'>Google Analytics Privacy &amp; Terms</a>
          </Typography>

          <Typography variant='body2'>
            If you wish, you may opt out of having your visit tracked by:
            <ul>
              <li>changing your browser settings to refuse cookies</li>
              <li>disabling JavaScript within your browser;</li>
              <li>
                downloading and installing a browser add-on from and/or{' '}
                <a href='https://tools.google.com/dlpage/gaoptout?hl=en'>Google.</a>
              </li>
            </ul>
          </Typography>
        </>
      </AgreementModalComponent>
    </>
  )
}

export default FooterComponent
