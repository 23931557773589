import React from 'react'
import format from 'date-fns/format'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import startOfDay from 'date-fns/startOfDay'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import { FieldAttributes, FormikProps } from 'formik'

import { useMediaHook } from '../../hooks/useMediaHook'

import { useStyles } from './style'

interface IProps {
  form: FormikProps<object>
  field: FieldAttributes<{
    name: string
    value: Date
  }>
  allowAnyDate: boolean
}

function shouldDisableDate(date: Date): boolean {
  const year = date.getFullYear()

  // dont allow dates before 2023 to be selected
  if (year < 2023) {
    return true
  }

  const month = date.getMonth()
  const day = date.getDate()

  // if march or earlier (temp check to test april data)
  // if (month < 3) {
  //   return true
  // }
  // is april or earlier
  if (month < 4) {
    return true
  }

  // is may but before the 15th
  if (month === 4 && day < 15) {
    return true
  }

  // after august
  if (month > 7) {
    return true
  }

  return false
}

export const CustomCalendar: React.FC<IProps> = ({ form, field }) => {
  const { isMobile } = useMediaHook()
  const classes = useStyles()

  const { name, value } = field
  const { setFieldValue } = form

  const onChange = (value: Date | null) => {
    setFieldValue(name, value)
  }

  return (
    <Stack className={classes.wrapper}>
      {isMobile ? (
        <MobileDatePicker
          slotProps={{
            dialog: { className: classes.mobileDialogClasses }
          }}
          aria-label={'Choose date'}
          maxDate={startOfDay(new Date())}
          value={new Date(value)}
          onChange={onChange}
          shouldDisableDate={shouldDisableDate}
          slots={{
            field: (params: any) => (
              <TextField
                {...params}
                value={format(params.value, 'MMMM dd, yyyy')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CalendarTodayRoundedIcon />
                    </InputAdornment>
                  )
                }}
              />
            )
          }}
        />
      ) : (
        <DesktopDatePicker
          aria-label={'Choose date'}
          showDaysOutsideCurrentMonth={true}
          slotProps={{
            desktopPaper: {
              className: classes.desktopDialogClasses
            }
          }}
          value={new Date(value)}
          format='MMMM dd, yyyy'
          maxDate={startOfDay(new Date())}
          slots={{
            openPickerIcon: CalendarTodayRoundedIcon
          }}
          onChange={onChange}
          shouldDisableDate={shouldDisableDate}
        />
      )}
    </Stack>
  )
}
