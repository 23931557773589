import React, { useState, useEffect } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { Title } from '../common/Title'
import { Wrapper } from '../common/Wrapper'
import { useStyles } from './styles'
import { CropTypesControls } from './CropTypesControls'
import { CommonButton } from '../../../shared/components/common-button'
import {
  CropTypeSetResponseRecord,
  useDeleteCropTypeSetMutation,
  useFetchCropTypeSetsQuery,
  useSetCropTypeSetAsActiveMutation,
  useLazyFetchCropTypesQuery
} from '../../../store/api/api'

const parseError = (error: FetchBaseQueryError | SerializedError): string => {
  return 'error' in error ? error.error : ''
}

const columns: GridColDef[] = [
  {
    field: 'cropTypeName',
    headerName: 'Crop Type',
    flex: 1
  },
  {
    field: 'label',
    headerName: 'Label',
    flex: 1
  },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1
  }
]

export const CropTypes: React.FC = () => {
  const classes = useStyles()
  const [cropTypeSet, setCropTypeSet] = useState<CropTypeSetResponseRecord | undefined>()
  const [setCropTypeSetAsActiveMutation, setAsActiveState] = useSetCropTypeSetAsActiveMutation()
  const [deleteCropTypeSetMutation, deleteCropTypeSetState] = useDeleteCropTypeSetMutation()
  const { currentData: cropTypeSets, refetch: refetchCropTypeSets, ...cropTypeSetState } = useFetchCropTypeSetsQuery()
  const [fetchCropTypes, cropTypes] = useLazyFetchCropTypesQuery()

  useEffect(() => {
    if (cropTypeSet) {
      fetchCropTypes(cropTypeSet.id)
    }
  }, [cropTypeSet, fetchCropTypes])

  useEffect(() => {
    // During initial load of the component via navigation, we want to re-fetch the list of crop type sets
    if (!cropTypeSetState.isFetching && cropTypeSets) {
      refetchCropTypeSets()
    }
  }, [])

  return (
    <Wrapper>
      <Title>Crop Types</Title>

      <p className={classes.description}>View, replace and create lists of available crop types</p>
      <CropTypesControls currentCropTypeSet={cropTypeSet} cropTypeSets={cropTypeSets} setCropTypeSet={setCropTypeSet} />

      {setAsActiveState.error ? <p className={classes.error}>{parseError(setAsActiveState.error)}</p> : null}
      {deleteCropTypeSetState.error ? (
        <p className={classes.error}>{parseError(deleteCropTypeSetState.error)}</p>
      ) : null}

      {cropTypeSet ? (
        <div className={classes.selectedSetControls}>
          <CommonButton
            type='button'
            disabled={deleteCropTypeSetState.isLoading}
            onClick={async () => {
              if (window.confirm('Are you sure? This action cannot be undone')) {
                await deleteCropTypeSetMutation(cropTypeSet.id)
                refetchCropTypeSets()
              }
            }}
          >
            Delete Crop Set
          </CommonButton>
          {!cropTypeSet.active ? (
            <CommonButton
              type='button'
              disabled={setAsActiveState.isLoading}
              onClick={async () => {
                await setCropTypeSetAsActiveMutation(cropTypeSet.id)
                refetchCropTypeSets()
              }}
            >
              Set As Active
            </CommonButton>
          ) : null}
        </div>
      ) : null}

      <div className={classes.tableWrapper}>
        <DataGrid columns={columns} rows={cropTypes.data || []} />
      </div>
    </Wrapper>
  )
}
