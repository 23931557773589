import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    margin: '10px auto'
  },
  error: {
    color: 'red',
    margin: '0 0 5px 0',
    fontSize: '0.8em'
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiInputBase-input': {
      padding: '10px'
    }
  },
  submitButton: {
    width: '100%',
    marginTop: '10px'
  }
}))
