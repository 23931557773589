import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
    margin: '0 0 5px 0',
    fontSize: '0.8em'
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '25px',
    '& .MuiInputBase-input': {
      padding: '10px'
    }
  },
  instructionParagraph: {
    fontSize: '1rem',
    '& ul': {
      margin: 0,
      paddingLeft: '15px'
    }
  },
  fileInput: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  },
  fileInputButton: {
    width: '150px'
  },
  submitButtonWraper: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  submitButton: {
    width: '300px'
  }
}))
