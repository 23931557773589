import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    maxWidth: '950px',
    margin: '20px auto',
    '& h1': {
      margin: '20px 0'
    }
  }
}))

export const Wrapper: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <Link to='/admin'>Admin Home</Link>
      {children}
    </div>
  )
}
