import React, { FC, RefObject } from 'react'
// import ReactDOMServer from 'react-dom/server'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
// import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'

import { setMapControlsAction } from '../../../../store/heatmap/reducer'
import { CommonIcon } from '../../../../shared/components/common-icon'
import { heatmapMapControlsSelector } from '../../../../store/heatmap/selectors'
// import { PdfComponent } from '../pdf-component'

import { useStyles } from './styles'
import { ColorVar } from '../../../../styles/variables'

interface IProps {
  screenUrl: string
  pageElement: RefObject<HTMLElement>
  detailsData: Array<{
    icon: string
    alt: string
    title: string
    detail: () => string | undefined
  }>
}

export const DownloadComponent: FC<IProps> = ({ pageElement }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const { center, zoomLevel } = useSelector(heatmapMapControlsSelector)
  const dispatch = useDispatch()

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget)
  // }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const DownloadIcon = <CommonIcon alt='Download icon' src='/images/icons/download.svg' />
  const DownloadIconGrey = <CommonIcon alt='Download icon' src='/images/icons/downloadGrey.svg' />
  const PrintIcon = <CommonIcon alt='Print icon' src='/images/icons/print.svg' />

  const onClickPrintHandler = () => window.print()

  const onClickSaveAsJPEGHandler = async () => {
    if (pageElement.current === null) {
      return
    }

    const canvas = await html2canvas(pageElement.current, {
      useCORS: true,
      allowTaint: true
    })

    const link = document.createElement('a')

    link.href = canvas.toDataURL()
    link.download = 'export-report.jpeg'

    //Firefox requires the link to be in the body
    document.body.appendChild(link)

    //simulate click
    link.click()

    //remove the link when done
    document.body.removeChild(link)
    handleClose()
  }

  // Need to fix issues with PDF renders before re-enabling
  // const onClickSaveAsPDFHandler = async () => {
  //   const doc = new JsPDF('p', 'px', 'a4')
  //   const width = doc.internal.pageSize.getWidth()
  //   const height = doc.internal.pageSize.getHeight()

  //   doc.html(
  //     ReactDOMServer.renderToStaticMarkup(
  //       <PdfComponent
  //         width={width}
  //         height={height}
  //         screenUrl={screenUrl}
  //         detailsData={detailsData}
  //         requestParams={requestParams}
  //       />
  //     ),
  //     {
  //       callback: () => {
  //         doc.save('export-report.pdf')
  //       }
  //     }
  //   )

  //   handleClose()
  // }

  return (
    <Box component={'section'} className={classes.box}>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        style={{
          color: open ? ColorVar.darkBlue : ColorVar.black
        }}
        onClick={onClickSaveAsJPEGHandler}
        className={classes.button}
        startIcon={open ? DownloadIcon : DownloadIconGrey}
      >
        Download JPEG
      </Button>
      {/* Need to fix issues with PDF renders before re-enabling */}
      {/* <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.downloadList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem className={classes.downloadMenuItem} disabled={!screenUrl} onClick={onClickSaveAsPDFHandler}>
          Save as PDF
        </MenuItem>
        <MenuItem className={classes.downloadMenuItem} onClick={onClickSaveAsJPEGHandler}>
          Save as JPEG
        </MenuItem>
      </Menu> */}
      <Button className={classes.button} startIcon={PrintIcon} onClick={onClickPrintHandler}>
        Print
      </Button>
      <Button
        className={classes.button}
        startIcon={<CloseIcon />}
        onClick={() => {
          dispatch(setMapControlsAction({ center, zoomLevel: zoomLevel + 1 }))
          navigate('/map')
        }}
      >
        Close
      </Button>
    </Box>
  )
}
