import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import MButton from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

import { Title } from '../common/Title'
import { Wrapper } from '../common/Wrapper'
import { CommonButton } from '../../../shared/components/common-button'
import { CommonAccordion } from '../../../shared/components/common-accordion'
import { useStyles } from './styles'
import { ColorVar } from '../../../styles/variables'
import { useCreateCropTypeSetMutation, ApiError } from '../../../store/api/api'

const initialValues: { year: string; file: null | File } = {
  year: '',
  file: null
}

const getFileName = (file: null | File): string => {
  if (!file) {
    return ''
  }
  const pieces = file.name.split(/\\|\//)
  return pieces[pieces.length - 1]
}

export const NewCropTypes: React.FC = () => {
  const classes = useStyles()

  const [createCropTypeSetMutation] = useCreateCropTypeSetMutation()
  const [globalErrors, setGlobalErrors] = useState<string[]>([])
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Title>New Crop Type Set</Title>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key: string]: string } = {}
          if (!values.year || !/^\d{4}$/.test(values.year)) {
            errors['year'] = 'Year must be in the format of a year. Ex: 2024'
          }
          if (!values.file) {
            errors['file'] = 'CSV file required with the columns: cropType, label, value'
          }

          return errors
        }}
        onSubmit={async (values, actions) => {
          setGlobalErrors([])
          if (!values.file) {
            actions.setFieldError('file', 'CSV file required with the columns: cropType, label, value')
            return
          }
          try {
            await createCropTypeSetMutation({
              name: values.year,
              file: values.file
            }).unwrap()
            navigate('/admin/crop-types')
          } catch (err: any) {
            if (err.data && err.data.errors) {
              setGlobalErrors(err.data.errors.map((er: ApiError) => er.message))
            } else {
              setGlobalErrors(['Unknown error'])
            }
          }
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isSubmitting, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              {globalErrors.map((er) => (
                <p className={classes.error}>{er}</p>
              ))}
              <div className={classes.fieldRow}>
                <FormLabel htmlFor='year'>Year</FormLabel>
                <TextField type='text' name='year' value={values.year} onBlur={handleBlur} onChange={handleChange} />
                {errors.year && touched.year ? <p className={classes.error}>{errors.year}</p> : null}
              </div>
              <div className={classes.fieldRow}>
                <FormLabel htmlFor='file'>CSV file for crop types</FormLabel>
                <MButton
                  component='label'
                  className={classes.fileInputButton}
                  role={undefined}
                  sx={{ color: ColorVar.black, backgroundColor: ColorVar.orange }}
                >
                  Upload File
                  <input
                    type='file'
                    name='file'
                    className={classes.fileInput}
                    onChange={(event) => {
                      setFieldTouched('file', true)
                      if (event.currentTarget.files) {
                        setFieldValue('file', event.currentTarget.files[0])
                      }
                    }}
                  />
                </MButton>
                {values.file ? getFileName(values.file) : null}
                {errors.file && touched.file ? <p className={classes.error}>{errors.file}</p> : null}
              </div>
              <CommonAccordion capitalize={false} title={'CSV file format details'}>
                <div className={classes.instructionParagraph}>
                  <p>The csv file should contain headers:</p>
                  <ul>
                    <li>cropType</li>
                    <li>label</li>
                    <li>value</li>
                  </ul>
                </div>
                <div className={classes.instructionParagraph}>
                  <p>The cropType column values allow:</p>
                  <ul>
                    <li>barley</li>
                    <li>durum</li>
                    <li>springWheat</li>
                    <li>winterWheat</li>
                  </ul>
                </div>
                <div className={classes.instructionParagraph}>
                  <p>The value column values allow:</p>
                  <ul>
                    <li>Resistant</li>
                    <li>Intermediate</li>
                    <li>Susceptible</li>
                  </ul>
                </div>
                <p>The label column can be any string value.</p>
              </CommonAccordion>
              <div className={classes.submitButtonWraper}>
                <CommonButton type='submit' disabled={isSubmitting} className={classes.submitButton}>
                  Create
                </CommonButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}
